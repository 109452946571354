// logos
$proradis-logo-wire-black: "../images/logo-proradis-wire-black.png";
$proradis-logo-wire-white: "../images/logo-proradis-wire-white.png";


// color palette
$yellow: rgb(245, 166, 35);
$orange: rgb(235, 123, 66);
$green: rgb(126, 211, 33);
$blue: rgb(74, 144, 226);
$purple: rgb(144, 48, 163);

//
