@import '_mixins';
@import '_variables';

body, html, main {
	height: 100%;
	font-size: 14px;
}

main { padding-top: 50px; }

.blue { color: $blue; }
.green { color: $green; }
.yellow { color: $yellow; }
.purple { color: $purple; }
.orange { color: $orange; }

h1, h2, h3 { margin: 10px 0; font-weight: bolder; }
h2 { font-size: 2em; }
h3 { font-size: 1.4em; }


.navbar-smartris { transition: 1.5s; }

.navbar-toggle {
	border: 1px solid rgba(255,255,255,1);
	.icon-bar { background-color: #FFF; }

}

.navbar-brand.invert-logo { background: url($proradis-logo-wire-white); background-size: 96px 34px; }
.navbar-brand {
	text-indent: -1000em;
	background: url($proradis-logo-wire-black);
	background-size: 96px 34px;
	margin-left: 15px;
	width: 96px;
	height: 34px;
	margin-top: 8px;
}

.canvas-fixed {
	position: fixed;
	z-index: -1;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
}

#hero {
    width: auto;
    height: 700px;
    background-position: bottom;
    text-align: center;
    z-index: 2;

	h1 {
		color: rgba(255,255,255,1);
		margin: 25px auto;
		text-shadow: 0px 1px 1px rgba(0,0,0,.3);
		font-size: 3.2em;
		letter-spacing: -1px;
	}
}

.hero-image {
	margin-top: 2em;
    max-width: 700px;

	@include phone {
		max-width: 500px;
	}

}

.hero-product-logo {
	width: 170px;
	margin-top: 35px;
}

.btn-border {
	background-color: transparent;
	border: 2px solid #E37239;
	border-radius: 3px;
	text-transform: uppercase;
	color: white;
	font-weight: bold;
	letter-spacing: 3px;
	font-size: 0.8em;
}

.hero-btn {
    display: block;
	margin:10px auto;
	width: 320px;
	background: #373737;
	border: 1px solid #000000;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50), inset 0px 1px 1px 0px rgba(255, 255, 255,.2);
	border-radius: 3px;
	font-size: 18px;
	font-weight: 700;
	color: #F5A623;
	letter-spacing: 1.28px;
	line-height: 25px;
	text-transform: uppercase;
}

.hero-btn:hover {
	color: darken(#F5A623, 5%);
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.50), inset 0px 1px 1px 0px rgba(255, 255, 255,.2);
	background-color: darken(#373737, 5%);
}

.hero-btn:active,
.hero-btn:focus {
	color: white;
}

#main_features {
    background-color: rgb(232, 232, 232);
    z-index: -1;
	padding-top: 180px;
	text-align: center;
	-moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	padding-bottom: 30px;

	@include tablet {
		padding-top: 225px;
	}

	@include phone {
		padding-top: 130px;
	}

	ul {
		list-style-type: none;
		li {
			display: inline-block;
			width: 33%;
			padding: 15px;
		}
	}
}

#second_best_feature {
	background-color: rgb(255,255,255);
	padding-top: 55px;
	padding-bottom: 55px;

	h2 {
		margin: 0 auto 55px auto;
		text-align: center;
		@include desktop {
			text-align: left;
		}

		@include tablet {
			text-align: left;
		}

		@include phone {
			text-align: center;
		}
	}

	.fa {
		margin-top: 15px;
	}

	.sbf-screen-1 {
		position: absolute;
		right: 0;
		top: 1050px;
		width: 50;
	}
	.sbf-screen-2 {
		position: absolute;
		right: 420px;
		top: 1300px;
		width: 489px;
	}
}

#exams_central {
	background-color: #DEDEDE;
	padding-top: 45px;
	padding-bottom: 45px;

	h2 {
		text-align: center;
		margin-bottom: 60px;
	}
	.fa {
		margin-top: 13px;
		color: darken($green, 50%);
	}
}

#clients {
	background-color: rgba(255,255,255,1);
	padding-top: 45px;
	padding-bottom: 0;
	h2 {
		margin-bottom: 55px;
		text-align: center;
	}
}

#register {
	background-color: rgba(240,240,240,1);
	padding-top: 55px;
	padding-bottom: 65px;

	h2,h3 {
		text-align: center;
	}

	form {
		text-align: left;
	}

	label {
		margin-top: 12px;
	}
	.error {
		color: red;
	}

	.register-btn {
	    display: block;
		margin:35px auto 0 auto;
		background: #E5722F;
		border: 1px solid lighten(#7F3F1A, 10%);
		box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.30), inset 0px 1px 1px 0px rgba(255, 255, 255,.2);
		border-radius: 3px;
		font-size: 18px;
		font-weight: 700;
		color: #FFF;
		letter-spacing: 1.28px;
		line-height: 25px;
		text-transform: uppercase;
	}

	.register-btn:hover {
		background: darken(#E5722F, 5%);
		border: 1px solid lighten(#7F3F1A, 3%);
		box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.30), inset 0px 1px 1px 0px rgba(255, 255, 255,.2);
		color: darken(#FFF, 2%);
		text-shadow: 0px 1px 1px rgba(0,0,0,.2);
	}

}

footer {
	background-color: rgba(30,30,30,1);
	color: rgba(255,255,255,1);
	padding: 40px 0;
	.company-address {
		border-top: 1px solid rgba(55,55,55,1);
		margin-top: 25px;
		text-align: center;
		padding-top: 10px;
		color: rgba(155,155,155,.7);
	}
	ul {
		list-style-type: none;
		padding-left: 0;
		float: right;
		padding-top: 15px;
		@include phone {
			padding-top: 35px;
			text-align: center;
			float: none;
		}
	}
	li {
		display: inline-block;

	}
	li:last-child::after { content: ""; }
	li::after {
		content: " |";
		color: #E37239;
		margin-right: 5px;
		margin-left: 5px;

	}

	.logo-footer {
		@include phone {
			text-align: center;
		}
	}

	a { color: #FFF; }
	a:hover { color: darken(#E37239, 0%) }

}

.grid-sizer,
.grid-item { width: 20%; }

// [class|=grid-item] {
// 	height: auto;
// }
/* 2 columns wide */
.grid-item--width2 { width: 40%; }
.grid--blue { background-color: $blue; }
.grid--green { background-color: $green; }
.grid--yellow { background-color: $yellow; }
