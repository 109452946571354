@mixin linear-gradient($fromColor, $toColor) {
    background-color: $toColor; /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image:    -moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
    background-image:     -ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
    background-image:      -o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
    background-image:         linear-gradient(top, $fromColor, $toColor);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

@mixin opacity($op) {
    -khtml-opacity: $op;
    -moz-opacity: $op;
    opacity: $op;
}



$tablet-width: 768px;
$phone-width: $tablet-width - 1px;
$desktop-width: 992px;
$largedesktop-width: 1200px;

@mixin phone {
    @media (max-width: #{$phone-width}) {
        @content;
    }
}


@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: #{$desktop-width}) and (max-width: #{$largedesktop-width - 1px}) {
        @content;
    }
}

@mixin largedesktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}
